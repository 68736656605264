<template>
  <div class="about">
    <div class="banner">
      <img src="@/assets/images/about/banner.png">
    </div>

    <!-- 公司简介 -->
    <div class="company-profile" id="company">
      <!-- 标题 -->
      <div class="title-container">
        <div class="left">
          <div class="en">About Bio-shine</div>
          <div class="zn">公司简介</div>
        </div>
        <div class="right">
          <div class="item" v-for="(item, index) in locationViewList" :key="index" @click="locationView(item.id)">
            {{ item.title }}</div>
        </div>
      </div>

      <div class="content">
        <div class="left-text">
          2018年，来自中山大学、同济大学、罗格斯大学等院校的有志青年齐集广州，凭借奋发有为、志同道合的理想，我们创立了宝晟（广州）生物信息技术有限公司。初创团队依托在行业领域内深耕数十年的专家指导团队，凭借在信息技术和生物医药领域的跨专业背景，为用户提供软件系统的设计、开发，业务数据处理、数据分析及系统集成实施、运行维护服务。短时间内用户覆盖全国二十余个省市及香港特别行政区。在公司的发展历程中，我们承担了多项国家级资源库，国家重点实验室，上市药企的信息化建设项目，申请了多项软件著作权、发明专利，参与了相关国家标准的制订和专著的编写。我们将一如既往为为用户提供优质的产品和服务，成就用户实现自我！
          <div class="btns">
            <div class="btn" @click="locationView('history')">发展历程</div>
            <div class=" btn" @click="locationView('honor')">荣誉资质</div>
          </div>
        </div>
        <img src="@/assets/images/about/about-intro.png" class="right-img" alt="">
      </div>

      <!-- 底部蓝色定位 -->
      <div class="bottom-blue">
        <div class="desc-container">
          <div>卓著的运营开发实力，奠定行业地位!</div>
          <div>Industry leader with leading-edge operations strategy! </div>
        </div>
      </div>
    </div>

    <!-- 发展历程 -->
    <div class="history" id="history">
      <div class="year-container">
        <div class="year-header">
          <div class="year-title">
            <div class="year-name">
              <div class="big-name">发展历程</div>
              <div class="small-name">Our History</div>
            </div>
          </div>
          <div class="name-right">
            <div class="right-bgr"></div>
            <div class="right-bgl"></div>
          </div>
        </div>
        <div class="year-line">
          <div class="line-left"></div>
          <div class="line-right"></div>
        </div>
        <div class="main">
          <div class="content">
            <div class="years">2022年</div>
            <div class="circle">
              <h2></h2>
              <span></span>
              <div class="triangle"></div>
            </div>
            <div class="right-line"></div>
            <div class="right-content">
              <div class="right-content-left">
                <div class="left-title">未来</div>
                <div class="left-line"></div>
                <ul class="left-content">
                  <li>开拓，从未止步；未来，不忘初心。</li>
                </ul>
              </div>
              <img class="img" :src="nowImg" />
            </div>
          </div>
          <div class="content" style="margin-top: 40px" v-for="(item, index) in developmentCourse" :key="index">
            <div class="years">{{ item.year }}</div>
            <div class="child-circle">
              <h2></h2>
              <span></span>
            </div>
            <div class="child-right-line"></div>
            <div class="right-content">
              <div class="right-content-left">
                <div class="left-title">{{ item.title }}</div>
                <div class="left-line"></div>
                <ul class="left-content" v-for="(itemA, index) in item.list" :key="index">
                  <li>{{ itemA.name }}</li>
                </ul>
              </div>
              <img class="img" :src="item.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 荣誉资质 -->
    <div class="honor-container" id="honor">
      <img src="@/assets/images/about/honor-Rectangle1.png" class="honor-imgs">
      <img src="@/assets/images/about/honor-Rectangle2.png" class="honor-imgs">
      <img src="@/assets/images/about/honor-Rectangle3.png" class="honor-imgs">
      <img src="@/assets/images/about/honor-Rectangle4.png" class="honor-imgs">
      <img src="@/assets/images/about/honor-Rectangle5.png" class="honor-imgs">

      <!-- <div class="imgs">
      </div> -->
    </div>
    <!-- 员工风采 -->
    <div class="employee-container" id="employee">
      <div class="public-title-container">
        <h1>员工风采</h1>
        <div>Leadership & Board</div>
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in employeeList" :key="index">
            <div class="swiper-content">
              <img class="swiper-banner-img" :src="item.img" alt="">
              <div v-show="false" class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <!-- <div class="swiper-pagination"></div> -->

        <!-- 如果需要导航按钮 -->
        <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->

        <!-- 如果需要滚动条 -->
        <!-- <div class="swiper-scrollbar"></div> -->
      </div>

    </div>


    <div class="public-title-container">
      <h1>联系我们</h1>
      <div>Contact us</div>
    </div>
    <!-- 联系我们 -->
    <div class="contant-us-container" id="contant">

      <div class="content">
        <div class="left-info">
          <div class="city">
            <img src="@/assets/images/about/location.png"><span>广州市天河区</span>
          </div>
          <div class="location">地址：广州市天河区五山路科华街251号乐天创意
            园A2栋6楼6018单元 </div>
          <div class="phone">产品咨询热线：020-88902717/020-88902718</div>
          <div class="eMail">邮箱：marketing@bioitbs.com</div>
        </div>
        <el-amap class="map" :center="[113.361773, 23.145949]" :zoom="12">
          <el-amap-marker v-for="(item, index) in mapMarker" :key="index" :position="item.position" :label="item.label"
            class="marker" />
        </el-amap>
      </div>
    </div>
  </div>

</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from "swiper"


export default {
  data() {
    return {
      employeeActived: 0, //员工风采选中效果
      employeeList: [
        {
          img: require("@/assets/images/about/2018banner01.png"),
          desc: "2018年终分享会",
        },
        {
          img: require("@/assets/images/about/2018banner2.png"),
          desc: "2018年终分享会",
        },
        {
          img: require("@/assets/images/about/2019banner1.png"),
          desc: "2019年3月份季度生日会",
        },
        {
          img: require("@/assets/images/about/2019banner2.png"),
          desc: "2019年3月份季度生日会",
        },
        {
          img: require("@/assets/images/about/2021banner1.png"),
          desc: "2021年3月清远别墅轰趴",
        },
        {
          img: require("@/assets/images/about/2021banner2.png"),
          desc: "2021年3月清远别墅轰趴",
        },
        {
          img: require("@/assets/images/about/2021-9-banner1.png"),
          desc: "2021年9月珠海桂山岛团建",
        },
        {
          img: require("@/assets/images/about/2021-9-banner2.png"),
          desc: "2021年9月珠海桂山岛团建",
        },
        {
          img: require("@/assets/images/about/2022-banner1.png"),
          desc: "2022年2月19号乔迁庆典",
        },
        {
          img: require("@/assets/images/about/2022-banner2.png"),
          desc: "2022年2月19号乔迁庆典",
        }

      ],
      mapMarker: [], //地图标点
      locationViewList: [
        {
          title: "公司简介",
          id: "company",
        },
        {
          title: "发展历程",
          id: "history",
        },
        {
          title: "荣誉资质",
          id: "honor",
        },
        {
          title: "员工风采",
          id: "employee",
        },
        {
          title: "联系我们",
          id: "contant",
        },
      ],
      // 发展历程
      nowImg: require('@/assets/images/about/development1.png'),
      developmentCourse: [
        {
          title: '公司发展',
          year: '2021年',
          list: [
            { name: '承担生物药企信息管理系统项目建设并协助企业新药上市获批' },
            {
              name:
                '承担省部省部共建中医湿证国家重点实验室信息系统平台建设',
            },
            {
              name:
                '组织编写人卫出版社《生物样本库信息管理系统功能设计与实践》专著',
            },
          ],
          image: require('@/assets/images/about/development5.png'),
        },
        {
          title: '公司成长',
          year: '2020年',
          list: [
            {
              name:
                '承担全国第一家通过ISO20387认可的综合性医院生物样本库信息管理系统建设',
            },
            {
              name: '承担“国家青藏高原动物资源共享平台”建设',
            },
            {
              name: '公司第一次参与行业相关国家标准制订',
            },
          ],
          image: require('@/assets/images/about/development3.png'),
        },
        {
          title: '公司初创',
          year: '2019年',
          list: [
            { name: '申请公司第一个发明专利' },
            {
              name: '获得公司第一个高新产品证书',
            },
          ],
          image: require('@/assets/images/about/development2.png'),
        },
        {
          title: '公司成立',
          year: '2018年',
          list: [{ name: '获得公司第一个软件著作权' }],
          image: require('@/assets/images/about/development4.png'),
        },
      ],
    }
  },
  created() {
    this.map()
  },
  mounted() {
    new Swiper('.swiper-container', {
      slidesPerView: 3,
      spaceBetween: 200,
      centeredSlides: true,
      autoplay: { //自动开始
        delay: 2500, //时间间隔
        disableOnInteraction: false, //*手动操作轮播图后不会暂停*
      },
      loop: true,
      grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChangeTransitionEnd: function () {
          // console.log(this.realIndex)
          this.employeeActived = this.realIndex
        },
      },
      /*       //direction: 'vertical', // 垂直切换选项
            //mousewheel: true, //滚轮
            autoplay: { //自动开始
              delay: 2500, //时间间隔
              disableOnInteraction: false, //*手动操作轮播图后不会暂停*
            },
            loop: true, // 循环模式选项
      
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
              clickable: true, // 分页器可以点击
            },
      
            // 如果需要前进后退按钮
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
      
            // 如果需要滚动条
            scrollbar: {
              el: '.swiper-scrollbar',
            }, */
      // 如果需要滚动条
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    })
  },

  methods: {
    map() {
      // 数据格式：
      this.mapMarker = [{
        position: [113.361773, 23.145949],
        label: { content: '广东省广州市天河区科华街251号乐天创意园', offset: [-100, -60] }
      }]
    },
    locationView(id) { //锚点定位   
      document.getElementById(id).scrollIntoView({
        behavior: "smooth" // 平滑过渡
      })
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  // transform: scaleX(0.35);
  transform: scale(0.7);

}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  // min-width: 600px;
  z-index: 1;
  box-shadow: 0px 3px 10px 10px #000000
}

.banner {
  max-width: 1920px;
  // height: 700px;
  margin: 0 auto;
  object-fit: cover;

  img {
    display: block;
    width: 100%;
  }
}

//公司简介
.company-profile {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  padding-top: 45px;

  // 标题
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .left {
      .en {
        height: 70px;
        font-size: 50px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #2A5CE9;
        line-height: 50px;
      }

      .zn {
        height: 56px;
        font-size: 40px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: 56px;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item {
        position: relative;
        height: 25px;
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        line-height: 25px;
        border-bottom: 1.5px solid rgb(116, 116, 116);
        margin-left: 45px;
        padding-bottom: 9px;
        padding-left: 15px;
        cursor: pointer;

        &::before {
          position: absolute;
          content: '';
          display: block;
          width: 7px;
          height: 7px;
          background: #000;
          border-radius: 50%;
          bottom: 50%;
          left: 0;
        }

        &:hover {
          color: #2A5CE9;
          border-bottom: 1.5px solid #2A5CE9;
        }

        &:hover::before {
          background: #2A5CE9;
        }
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .left-text {
      font-size: 18px;
      color: #000000;
      line-height: 37px;
      letter-spacing: 3px;

      .btns {
        display: flex;
        align-items: center;
        margin-top: 23px;



        .btn {
          width: 136px;
          height: 44px;
          font-size: 18px;
          color: #000;
          line-height: 44px;
          text-align: center;
          border: 1px solid #2A5CE9;
          border-radius: 50px;
          box-sizing: border-box;
          cursor: pointer;

          &:nth-child(2) {
            margin-left: 27px;
          }

          &:hover {
            background: #2A5CE9;
            color: #fff;
          }
        }
      }
    }

    .right-img {
      display: block;
      width: 508px;
      height: 664px;
      margin-left: 94px;
    }
  }

  .bottom-blue {
    position: absolute;
    bottom: 0;
    width: 1200px;
    height: 153px;
    background: #2A5CE9;
    z-index: -1;

    .desc-container {
      padding: 43px 0 43px 68px;
      box-sizing: border-box;

      div {

        &:first-child {
          height: 45px;
          font-size: 32px;
          color: #FFFFFF;
          line-height: 45px;
        }

        &:last-child {
          height: 22px;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
    }



  }
}

//发展历程
.history {
  padding: 80px 0 88px;
}

.year-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.year-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin-bottom: 7px;

  .year-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .big-name {
      font-size: 40px;
      font-weight: bold;
      color: #000000;
      height: 56px;
      line-height: 56px;
    }

    .small-name {
      font-size: 16px;
      color: #969696;
    }
  }

  .name-right {
    height: 37px;
    display: flex;

    .right-bgr {
      width: 158px;
      background: #2a5ce9;
    }

    .right-bgl {
      width: 78px;
      background: #21ceca;
    }
  }
}

.year-line {
  display: flex;
  margin-bottom: 157px;
  height: 6px;

  .line-left {
    width: 800px;
    background: #2a5ce9;
  }

  .line-right {
    width: 400px;
    background: #21ceca;
  }
}

.main {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .content {
    display: flex;
    align-items: center;

    .years {
      font-size: 32px;
      font-weight: bold;
      color: #000000;
      margin-right: 48px;
    }

    .right-content {
      width: 791px;
      height: 179px;
      padding: 0 17px;
      background: #f6f9ff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px 8px 8px 8px;

      .right-content-left {
        .left-title {
          font-size: 32px;
          font-weight: bold;
          color: #2a5ce9;
        }

        .left-line {
          width: 518px;
          height: 0px;
          border: 1px solid #c3c3c3;
          margin: 12px 0 23px;
        }

        .left-content {
          font-size: 16px;
          padding: 0;
          color: #000000;
          overflow: hidden;

          li {
            list-style: none;
            padding: 0px;
            margin: 0px;
            line-height: 25px;
          }

          li:before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #2a5ce9;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
    }

    .img {
      width: 187px;
      height: 146px;
      background-color: pink;
    }
  }

  .right-line {
    width: 107px;
    height: 0px;
    border: 2px solid #21ceca;
    background-color: #21ceca;
  }

  .child-right-line {
    width: 107px;
    height: 0px;
    border: 2px solid #2a5ce9;
    background-color: #2a5ce9;
  }

  /*   .child-circle::before {
    display: block;
    content: '';
    position: absolute;
    top: -100px;
    left: 50%;
    width: 0px;
    height: 100px;
    border-left: 3px dashed #2a5ce9;
  } */

  .child-circle::before {
    display: block;
    content: '';
    position: absolute;
    top: -182px;
    left: 50%;
    width: 0px;
    height: 179px;
    border-left: 3px dashed #2a5ce9;
  }

  .child-circle {
    margin: 0 13px;
    position: relative;
    width: 33px;
    height: 33px;
    background: #2a5ce9;
    border-radius: 50%;

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 44%;
      // left: 50%;
      width: 100px;
      height: 4px;
      background: #2a5ce9;
    }
  }

  .circle::before {
    display: block;
    content: '';
    position: absolute;
    top: -100px;
    left: 50%;
    width: 0px;
    height: 100px;
    border-left: 3px dashed #2a5ce9;
  }

  .circle {
    position: relative;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #21ceca;
    text-align: center;
    margin: 0 auto;
    z-index: 1;

    h2 {
      position: absolute;
      top: 30%;
      left: 50%;
      z-index: 999;
      transform: translateX(-50%);
    }

    span {
      position: absolute;
      top: 60%;
      left: 50%;
      z-index: 999;
      transform: translateX(-50%);
    }

    .triangle {
      width: 0;
      height: 0;
      top: -129px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 28px solid #2a5ce9;
    }
  }

  .circle::after {
    content: '';
    width: 33px;
    height: 33px;
    background: #21ceca;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
}



//荣誉资质
.honor-container {
  // position: relative;
  max-width: 1920px;
  height: 717px;
  margin: 0 auto;
  background: url("../../assets/images/about/honor-bg.png") no-repeat center 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 100px;

  img {
    width: 275px;
    height: 394px;
    margin-bottom: 68px;
  }

}

// 公共标题
.public-title-container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 60px 0 38px;

  h1 {
    height: 56px;
    font-size: 40px;
    font-weight: bold;
    color: #000000;
    line-height: 40px;
  }

  div {
    height: 22px;
    font-size: 16px;
    color: #969696;
    line-height: 0px;
  }
}

//员工风采
.employee-container {
  max-width: 1500px;
  margin: 0 auto;

  .swiper-content {
    position: relative;

    .swiper-banner-img {
      width: 576px;
      // height: 400px;
      object-fit: cover;
    }

    .desc {
      position: absolute;
      width: 576px;
      height: 63px;
      background: #2A5CE9;
      opacity: 0.73;
      bottom: 0;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 63px;
    }
  }

}

//联系我们
.contant-us-container {
  width: 1200px;
  margin: 0 auto;
  padding: 28px 0;
  border: 1px solid #2A5CE9;
  box-sizing: border-box;
  margin-bottom: 61px;

  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;


    .left-info {
      width: 353px;

      .city {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        img {
          display: block;
          width: 41px;
          height: 41px;
          margin-right: 20px;
        }

        span {
          height: 25px;
          font-size: 18px;
          font-weight: bold;
          color: #646464;
          line-height: 25px;
        }
      }


      .location {
        margin-top: 15px;
        // height: 48px;
        font-size: 16px;
        color: #646464;
        line-height: 25px;
      }

      .phone {
        height: 22px;
        font-size: 16px;
        color: #646464;
        line-height: 22px;
        margin: 10px 0;
      }


      .eMail {
        height: 22px;
        font-size: 16px;
        color: #646464;
        line-height: 22px;
      }

    }

    .map {
      // background-color: skyblue;
      border: 1px solid skyblue;
      width: 563px;
      height: 298px;
      box-sizing: border-box;
    }
  }
}
</style>